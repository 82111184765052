<template>
  <div class="w-80 border-l pl-10">
    <h3 class="uppercase text-sm text-gray-500 mb-4">Campaign Summary:</h3>
    <modal-small class="w-1/2 h-1/2" v-if="showTextInfo" @close="showTextInfo = false">
      <div class="p-4 w-full">
        <p class="text-left mb-4">{{ textInfo }}</p>
      </div>
    </modal-small>
    <div class="flex flex-col">
      <div class="flex items-center mb-6">
        <div class="rounded-full flex items-center justify-center w-12 h-12 bg-gray-100 mr-2">
          <icon name="detached" class="w-8 h-8 text-accent" />
        </div>
        <div class="ml-4">
          <div>
            <span class="text-2xl font-bold">
              <loader class="relative" size="sm" v-if="propertyCountTotal === null" />
              <span v-else>{{ propertyCountTotal }}</span>
            </span> Properties
            <button class="text-accent" @click="showInfoWithText('The total number of properties within your selected Localities/Streets, irrespective of any price filters.')">
              <icon name="information" class="cursor-pointer inline leading-none w-4 h-4" />
            </button>
          </div>
          <small>in selected locations</small>
        </div>
      </div>

      <hr class="my-4">

      <div class="flex items-center mb-6">
        <div class="rounded-full flex items-center justify-center w-12 h-12 bg-green-100 mr-2">
          <icon name="sterling" class="w-8 h-8 text-green-600" />
        </div>
        <div class="ml-4">
          <div>
            <span class="text-2xl font-bold">
              <loader class="relative" size="sm" v-if="propertyCurrentYearCountTotal === null" />
              <span v-else>{{ propertyCurrentYearCountTotal }}</span>
            </span> Properties
            <button class="text-accent" @click="showInfoWithText('The total number of properties with a selected anniversary within this calendar year (past and future). Indicative of the number of cards you will send within a full calendar year.')">
              <icon name="information" class="cursor-pointer inline leading-none w-4 h-4" />
            </button>
          </div>
          <small>with selected Anniversaries this year</small>
          <div class="mt-2">
            <span class="text-2xl font-bold">{{ moneyFormat(pricePerCard * (propertyCurrentYearCountTotal + seedCardCountTotal))  }}</span> per Annum
          </div>
        </div>
      </div>

      <div class="flex items-center mb-6">
        <div class="rounded-full flex items-center justify-center w-12 h-12 bg-green-100 mr-2">
          <icon name="sterling" class="w-8 h-8 text-green-600" />
        </div>
        <div class="ml-4">
          <div>
            <span class="text-2xl font-bold">
              <loader class="relative" size="sm" v-if="propertyCurrentMonthCountTotal === null" />
              <span v-else>{{ propertyCurrentMonthCountTotal }}</span>
            </span> Properties
            <button class="text-accent" @click="showInfoWithText('The total number of properties with a selected anniversary within this calendar month (past and future). Indicative of the number of cards you will send within a full calendar month.')">
              <icon name="information" class="cursor-pointer inline leading-none w-4 h-4" />
            </button>
          </div>
          <small>with selected Anniversaries this month</small>
          <div>
            <span class="text-2xl font-bold">{{ moneyFormat(pricePerCard * (propertyCurrentMonthCountTotal + seedCardCountTotal))  }}</span> this Month
          </div>
        </div>
      </div>

      <hr class="my-4">

      <div class="uppercase text-sm text-gray-300">Cost Per Item: <span class="text-black">{{ moneyFormat(pricePerCard) }}</span></div>

      <div class="flex mt-4">
        <div v-if="campaign.active && campaign.lastOrderTimestamp" class="uppercase text-sm text-gray-500 mb-4 mr-6"> Last Print Date: <br>
          {{ dateFormat(campaign.lastOrderTimestamp, 'dd/MM/yyyy') }}
        </div>
        <div v-if="campaign.active && campaign.nextOrderTimestamp" class="uppercase text-sm text-gray-500 mb-4"> Next Print Date: <br>
          {{ dateFormat(campaign.nextOrderTimestamp, 'dd/MM/yyyy') }}
        </div>
      </div>
    </div>
    <hr class="my-4">
    <div class="flex flex-grow my-2">
      Download 'Sent to Property data'
      <span class="rounded-full w-4 h-4 bg-accent text-white flex items-center justify-center text-xs relative group ml-2">
      ?
      <div class="absolute top-0 left-0 z-10 pointer-events-none p-2 mt-6 border border-accent bg-white text-black w-48 text-left text-sm leading-4 shadow rounded hidden group-hover:block">
              Download a spreadsheet of all the properties you have sent Anniversary Cards to from this campaign.
      </div>
    </span>
    </div>
    <div class="flex flex-grow rounded bg-white border border-gray-300 z-20 py-3 px-4 justify-center items-center cursor-pointer" @click="downloadModalFlag = true">
      <label class="text-lg  cursor-pointer"> Download </label><icon name="download" class="w-5 h-5 ml-2  cursor-pointer"></icon>
    </div>
    <modal-small class="w-1/2 h-1/2" overflowClass="overflow-y-visible" v-if="downloadModalFlag" @close="downloadModalFlag = false">
      <div  id="downloadModal">
        <div class="relative flex items-center justify-center p-6" v-if="downloading">
          <loader class="relative" />
        </div>
        <div v-else>
          <div class="p-4 w-full">
            <h2 class="flex flex-grow text-lg font-bold">Download Addresses <icon name="download" class="w-5 h-5 ml-2 text-blue-600"></icon></h2>
            <p class="text-left my-4">Download a spreadsheet of all the properties you have sent Anniversary Cards to from this campaign.</p>
            <date-picker-input
              v-model="dateRange.startDate"
              label="From"
              placeholder="[Not Set]"
              :clear-button="true"
              class-name="w-full mb-2"
            />
            <date-picker-input
              v-model="dateRange.endDate"
              label="To"
              placeholder="[Not Set]"
              :clear-button="true"
              class-name="w-full mb-2"
            />
          </div>
          <div class="py-2 w-full flex justify-end mb-2">
            <button class="btn bg-blue-600 text-white mr-5 w-1/3" @click="downloadPropertyReport()">
              <label class="cursor-pointer"> Download </label>
            </button>
          </div>
        </div>
      </div>
    </modal-small>
  </div>
</template>

<script>

import ModalSmall from '@/components/ModalSmall'
import MoneyUtils from '@/MoneyUtils'
import {DateTime} from "luxon";
import http from '@/http'

export default {
  name: 'AnniversaryCampaignSummary',
  props: ["campaign", 'pricePerCard', 'propertyCurrentYearCountTotal', 'propertyCurrentMonthCountTotal', 'propertyCountTotal'],
  components: {ModalSmall},
  data () {
    return {
      loadingPropertyCounts: false,
      loadingCampaignPropertyCounts: false,
      seedCardCountTotal: 0,
      showTextInfo: false,
      textInfo: null,
      downloadModalFlag: false,
      dateRange: {
        startDate: null,
        endDate: null
      },
      downloading: false
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    moneyFormat: MoneyUtils.format,
    dateFormat: (timestamp, format) => {
      return DateTime.fromSeconds(timestamp).toFormat(format)
    },
    showInfoWithText (message) {
      this.textInfo = message
      this.showTextInfo = true
    },
    downloadPropertyReport () {
      this.downloading = true
      http.post(`campaigns/${this.campaign.id}/anniversary-orders/download-report`, {
        date_range: this.dateRange,
        responseType: 'arraybuffer'
      }).then(response => {
        this.downloading = false
        let downloadFile = new Blob([response.data], { type: response.headers['content-type'] })

        let url = URL.createObjectURL(downloadFile)

        let link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Properties Sent Anniversary Cards Report.csv')

        var target = document.getElementById('downloadModal')
        target.appendChild(link)
        link.click()
        target.removeChild(link)
      }, () => {
        this.downloading = false
      })
    }
  },
  watch: {
  }
}
</script>

