import http from '@/http'

export default {
  getAutocompletePostcodes (postcode) {
    return http.get('regions/postcodes/autocomplete', {
      params: {
        postcode
      }
    })
  },

  getFigures (regionIds, type, regionFilters) {
    return http.post('regions/figures', {
      regionIds,
      type,
      regionFilters
    })
  },

  updatePercentages (regionIds, percentages) {
    return http.post('regions/percentages', {
      regionIds,
      percentages
    })
  },

  getChartData (regionIds, type, regionFilters) {
    return http.post('regions/charts', {
      regionIds,
      type,
      regionFilters
    })
  },

  /**
   * Returns the total number of known streets within a region.
   *
   * @param {Array} regionIds Region identifier array.
   * @param {string} type Property type filter.
   * @param {string} search
   * @returns {Promise<number>} Number of known streets.
   */
  getNumberOfStreets (regionIds, type, search) {
    return http.get('regions/number-of-streets', {
      params: {
        regionIds,
        type,
        search
      }
    })
  },

  getTopPerformingStreets (regionIds, type, sort, page = 1, search) {
    return http.get('regions/top-performing-streets', {
      params: {
        regionIds,
        type,
        sort,
        page,
        search
      }
    })
  },

  getYearlyStreetData (regionIds, street, locality, type, district, sector) {
    return http.get(`regions/top-performing-streets/${street}`, {
      params: {
        regionIds,
        district,
        sector,
        locality,
        type
      }
    })
  },

  getTopPerformingLocalities (regionIds, type, sort) {
    return http.get('regions/top-performing-localities', {
      params: {
        regionIds,
        type,
        sort
      }
    })
  },

  getYearlyLocalityData (regionIds, locality, type, district, sector) {
    return http.get(`regions/top-performing-localities/${locality}`, {
      params: {
        regionIds,
        district,
        sector,
        type
      }
    })
  },

  getStreetTransactions (regionIds, street, locality, type, district, sector) {
    return http.get(`regions/streets/${street}`, {
      params: {
        regionIds,
        district,
        sector,
        locality,
        type
      }
    })
  },

  getLocalityTransactions (regionIds, locality, type, district, sector) {
    return http.get(`regions/locality/${locality}`, {
      params: {
        regionIds,
        district,
        sector,
        locality,
        type
      }
    })
  },

  getSectorsForRegions (regionIds) {
    return http.get(`regions/sectors`, {
      params: {
        regionIds,
      }
    })
  },

  getCampaigns () {
    return http.get(`campaigns`)
  },

  createCampaign (isAnniversary) {
    return http.post(`campaigns/create`, {
      isAnniversary
    })
  },

  getCampaignLocations (campaignId) {
    return http.get(`campaigns/${campaignId}/locations`)
  },

  addCampaignLocation (regionId, campaignId, pcDistrict, pcSector, location, isLocality, count, locality = "") {
    return http.post(`campaigns/${campaignId}/locations/add`, {
      regionId,
      pcDistrict,
      pcSector,
      location,
      isLocality,
      count,
      locality
    })
  },
  getAnniversaryTypes () {
    return http.get(`campaigns/anniversary-types`)
  },

  saveAnniversaryItem (campaignId, typeId, templateId=null) {
    return http.post(`campaigns/anniversary-types/save-item`, {
      campaignId: campaignId,
      typeId: typeId,
      templateId: templateId
    })
  },
  deleteAnniversaryItem(campaignId, typeId) {
    return http.post(`campaigns/anniversary-types/delete-item`, {
      campaignId: campaignId,
      typeId: typeId
    })
  },
  getCampaignPropertyCounts (campaignId) {
    return http.get(`campaigns/${campaignId}/property-counts`)
  },
  getAnniversaryCampaignTransactions (campaignId) {
    return http.get(`campaigns/${campaignId}/anniversary-counts`)
  },

  removeCampaignLocation (campaignId, pcDistrict, pcSector, location, count, locality = "") {
    return http.post(`campaigns/${campaignId}/locations/remove`, {
      pcDistrict,
      pcSector,
      location,
      count,
      locality
    })
  },

  updateCampaign (campaign, forcePrint=false) {
    return http.post(`campaigns/${campaign.id}`, {
      name: campaign.name,
      active: campaign.active,
      anniversaryFlag: campaign.anniversaryFlag,
      frequency: campaign.frequency,
      templateId: campaign.templateId,
      stockWeight: campaign.stockWeight,
      councilTaxFilter: campaign.councilTaxFilter,
      priceFilter: campaign.priceFilter,
      seedCard: campaign.seedCard,
      forcePrint: forcePrint,
      priceDecreaseTemplateId: campaign.priceDecreaseTemplateId,
      sendOnPriceDecrease: campaign.sendOnPriceDecrease,
      anniversaryItems: campaign.anniversaryItems
    });
  },

  deleteCampaign (campaign) {
    return http.post(`campaigns/${campaign.id}/delete`)
  },

  getCampaignLocationsCount (campaignId, location) {
    return http.get(`campaigns/${campaignId}/locations/${location}`)
  },

  getGeoJson (regionId) {
    return http.get(`regions/${regionId}/geojson`)
  },

  /**
   * Returns the total number of property sales within a region.
   *
   * @param {Array} regionIds Region identifier array.
   * @param {string} type     Property type filter.
   *
   * @return {Promise<int>} Number of property sales.
   */
  getPropertySales (regionIds, type) {
    return http.get(`regions/number-of-sales`, {
      params: {
        regionIds,
        type
      }
    })
  },

  /**
   * Get any campaign locations for the current user which do not have a set or unique locality.
   */
  getAmbiguousCampaignLocations () {
    return http.get('regions/ambiguous-locations')
  },

  fixAmbiguousCampaignLocations (locations) {
    return http.post('regions/ambiguous-locations', {
      locations
    })
  }
}
