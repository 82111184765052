<template>
  <div class="relative flex items-center" :class="{ 'flex-row-reverse': reverse }">
    <icon v-if="type === 'information'" name="information" class="icon w-5 h-5 text-accent mx-auto" />
    <icon v-else name="attention" class="icon w-5 h-5 text-red-600" />
    <div class="hidden ml-8 absolute text-left w-72 bg-gray-300 p-2 rounded-lg z-10" :class="{ 'mr-12': reverse, 'w-112': wide, 'bg-gray-700 text-white': dark }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolTip',
  props: {
    type: {
      type: String,
      required: false
    },
    wide: {
      type: Boolean,
      required: false
    },
    reverse: {
      type: Boolean,
      required: false
    },
    dark: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style scoped>
.icon:hover + div {
  display: block;
}
</style>